<template>
  <div class="card">
    <audio
      ref="audioPlayer"
      @ended="playNextSong"
      @play="onPlay"
      @pause="onPause"
    ></audio>
    <!-- top -->
    <div class="top">
      <el-row type="flex" justify="space-between">
        <el-col :span="7">
          <!-- 左边的动画 -->
          <div
            class="pfp"
            :style="{ backgroundImage: 'url(' + currentSongImage + ')' }"
          >
            <div class="playing">
              <div class="greenline line-1"></div>
              <div class="greenline line-2"></div>
              <div class="greenline line-3"></div>
              <div class="greenline line-4"></div>
              <div class="greenline line-5"></div>
              <div class="greenline line-6"></div>
            </div>
          </div>
        </el-col>

        <el-col :span="16">
          <!-- 右边的提示词 -->
          <p class="title-1">{{ currentSongTitle }}</p>
          <p class="title-2">{{ currentSongAutor }}</p>
        </el-col>

        <!-- <el-col :span="7">
          <img src="@/assets/mp3/imgs/相爱.jpeg" alt="" style="width: 100%;">
        </el-col> -->
      </el-row>
    </div>

    <!--控制按钮  -->
    <div class="controls">
      <!-- 音量控制键 -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        height="20"
        width="24"
        class="volume_button"
        @click="changeVolumeCtrl"
      >
        <path
          clip-rule="evenodd"
          d="M11.26 3.691A1.2 1.2 0 0 1 12 4.8v14.4a1.199 1.199 0 0 1-2.048.848L5.503 15.6H2.4a1.2 1.2 0 0 1-1.2-1.2V9.6a1.2 1.2 0 0 1 1.2-1.2h3.103l4.449-4.448a1.2 1.2 0 0 1 1.308-.26Zm6.328-.176a1.2 1.2 0 0 1 1.697 0A11.967 11.967 0 0 1 22.8 12a11.966 11.966 0 0 1-3.515 8.485 1.2 1.2 0 0 1-1.697-1.697A9.563 9.563 0 0 0 20.4 12a9.565 9.565 0 0 0-2.812-6.788 1.2 1.2 0 0 1 0-1.697Zm-3.394 3.393a1.2 1.2 0 0 1 1.698 0A7.178 7.178 0 0 1 18 12a7.18 7.18 0 0 1-2.108 5.092 1.2 1.2 0 1 1-1.698-1.698A4.782 4.782 0 0 0 15.6 12a4.78 4.78 0 0 0-1.406-3.394 1.2 1.2 0 0 1 0-1.698Z"
          fill-rule="evenodd"
        ></path>
      </svg>

      <div class="volume" :style="{ opacity: volumeCtrl }">
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          v-model="volume"
          @change="updateVolume"
          class="myvolume"
        />
      </div>

      <!-- 上一首 -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        height="24"
        width="24"
        @click="playLastSong()"
      >
        <path
          clip-rule="evenodd"
          d="M12 21.6a9.6 9.6 0 1 0 0-19.2 9.6 9.6 0 0 0 0 19.2Zm.848-12.352a1.2 1.2 0 0 0-1.696-1.696l-3.6 3.6a1.2 1.2 0 0 0 0 1.696l3.6 3.6a1.2 1.2 0 0 0 1.696-1.696L11.297 13.2H15.6a1.2 1.2 0 1 0 0-2.4h-4.303l1.551-1.552Z"
          fill-rule="evenodd"
        ></path>
      </svg>
      <!-- 暂停、播放 -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        height="24"
        width="24"
        @click="togglePlay"
      >
        <path
          clip-rule="evenodd"
          d="M21.6 12a9.6 9.6 0 1 1-19.2 0 9.6 9.6 0 0 1 19.2 0ZM8.4 9.6a1.2 1.2 0 1 1 2.4 0v4.8a1.2 1.2 0 1 1-2.4 0V9.6Zm6-1.2a1.2 1.2 0 0 0-1.2 1.2v4.8a1.2 1.2 0 1 0 2.4 0V9.6a1.2 1.2 0 0 0-1.2-1.2Z"
          fill-rule="evenodd"
        ></path>
      </svg>
      <!-- 下一首 -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        height="24"
        width="24"
        @click="playNextSong"
      >
        <path
          clip-rule="evenodd"
          d="M12 21.6a9.6 9.6 0 1 0 0-19.2 9.6 9.6 0 0 0 0 19.2Zm4.448-10.448-3.6-3.6a1.2 1.2 0 0 0-1.696 1.696l1.551 1.552H8.4a1.2 1.2 0 1 0 0 2.4h4.303l-1.551 1.552a1.2 1.2 0 1 0 1.696 1.696l3.6-3.6a1.2 1.2 0 0 0 0-1.696Z"
          fill-rule="evenodd"
        ></path>
      </svg>
      <div class="air"></div>
      <!-- 点赞 -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke-linejoin="round"
        stroke-linecap="round"
        class="heart"
        stroke="currentColor"
        fill="none"
        height="20"
        width="24"
      >
        <path
          d="M3.343 7.778a4.5 4.5 0 0 1 7.339-1.46L12 7.636l1.318-1.318a4.5 4.5 0 1 1 6.364 6.364L12 20.364l-7.682-7.682a4.501 4.501 0 0 1-.975-4.904Z"
        ></path>
      </svg>
    </div>

    <!-- 音乐进度条 -->
    <div class="song-time">
      <p class="timetext time_now">{{ currentProgress }}</p>

      <!-- <input class="time" type="range" ref="progressBar" min="0" :max="currentSongDuration" :value="currentProgress" @change="seekTo" /> -->
      <div class="time">
        <div class="elapsed" :style="{ width: currentPercent + '%' }"></div>
      </div>
      <p class="timetext time_full">{{ currentSongDuration }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MusicPlayer",
  data() {
    return {
      volumeCtrl: 0, //初始不展示音量控制键
      volume: 0.5, //初始音量
      nowtime: 0,
      alltime: 0,
      currentProgress: "", // 当前播放进度（单位：分钟）
      currentSongDuration: "", // 当前歌曲总时长（单位：分钟）
      currentPercent: 0.0, //当前歌曲进度的百分比
      currentSongIndex: 0,
      isPlaying: false,
      songs: [
        {
          src: "https://www.1ys53p.top/1718594008796.mp3",
          title: "相爱",
          autor: "脆莓乐队",
          img: require("@/assets/mp3/imgs/相爱.jpeg"),
        },
        {
          src: "https://www.1ys53p.top/1718594207670.mp3",
          title: "Cool kids",
          autor: "PRETTY YOUNG",
          img: require("@/assets/mp3/imgs/CoolKids.jpeg"),
        },
        {
          src: "https://www.1ys53p.top/1718594292243.mp3",
          title: "Bloody Mary Girl",
          autor: "She Her Her Hers",
          img: require("@/assets/mp3/imgs/BloodyMaryGirl.jpeg"),
        },
        {
          src: "https://www.1ys53p.top/1718594138418.mp3",
          title: "Young And Beautiful",
          autor: "Lana Del Rey",
          img: require("@/assets/mp3/imgs/youngAndBeautiful.jpeg"),
        },
        {
          src: "https://www.1ys53p.top/1718594233822.mp3",
          title: "BulletProof",
          autor: "Griffin Oskar",
          img: require("@/assets/mp3/imgs/bulletProof.jpeg"),
        },
        {
          src: "https://www.1ys53p.top/1718594092296.mp3",
          title: "日落海岸线",
          autor: "浪旅 LongTravel",
          img: require("@/assets/mp3/imgs/日落海岸线.jpg"),
        },
        {
          src: "https://www.1ys53p.top/1718594183077.mp3",
          title: "III",
          autor: "Athletics",
          img: require("@/assets/mp3/imgs/III.jpg"),
        },
      ],
    };
  },
  computed: {
    currentSong() {
      return this.songs[this.currentSongIndex];
    },
    currentSongTitle() {
      return this.currentSong ? this.currentSong.title : "";
    },
    currentSongAutor() {
      return this.currentSong ? this.currentSong.autor : "";
    },
    currentSongUrl() {
      return this.currentSong ? this.currentSong.src : "";
    },
    currentSongImage() {
      return this.currentSong ? this.currentSong.img : "";
    },
  },
  methods: {
    // 控制音量是否展示
    changeVolumeCtrl() {
      if (this.volumeCtrl == 1) {
        this.volumeCtrl = 0;
      } else {
        this.volumeCtrl = 1;
      }
    },
    // 控制音量
    updateVolume() {
      this.$refs.audioPlayer.volume = this.volume;
    },
    // 将秒转化为分钟：
    formatSecondsToMSS(seconds) {
      // 确保输入的是数值类型
      seconds = parseInt(seconds, 10);
      console.log(seconds);
      // 计算分钟数
      const minutes = Math.floor(seconds / 60);

      // 计算剩余的秒数
      const remainingSeconds = seconds % 60;

      // 格式化输出为两位数（不足两位前面补零）
      const formattedMinutes = minutes.toString().padStart(2, "0");
      const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

      // 返回格式化后的时间字符串
      return `${formattedMinutes}:${formattedSeconds}`;
    },
    togglePlay() {
      if (this.isPlaying) {
        this.$refs.audioPlayer.pause();
      } else {
        this.$refs.audioPlayer.play();
      }
    },
    // 播放下一首歌
    playNextSong() {
      this.currentSongIndex = (this.currentSongIndex + 1) % this.songs.length;
      this.$refs.audioPlayer.src = this.currentSong.src;
      this.$refs.audioPlayer.load(); // 确保加载新的源
      this.$refs.audioPlayer.play().catch((error) => {
        console.error("播放错误:", error);
      });
      // 获取歌曲总时长
      this.$refs.audioPlayer.addEventListener("loadedmetadata", () => {
        this.currentSongDuration = this.formatSecondsToMSS(
          this.$refs.audioPlayer.duration
        );
      });
    },
    // 播放上一首歌
    playLastSong() {
      if (this.currentSongIndex == 0) {
        console.log("已经是第一首歌");
      } else {
        this.currentSongIndex = (this.currentSongIndex - 1) % this.songs.length;
        this.$refs.audioPlayer.src = this.currentSong.src;
        this.$refs.audioPlayer.load(); // 确保加载新的源
        this.$refs.audioPlayer.play().catch((error) => {
          console.error("播放错误:", error);
        });
      }
      // 获取歌曲总时长
      this.$refs.audioPlayer.addEventListener("loadedmetadata", () => {
        this.currentSongDuration = this.formatSecondsToMSS(
          this.$refs.audioPlayer.duration
        );
      });
    },
    // 播放
    onPlay() {
      this.isPlaying = true;
      this.$refs.audioPlayer.addEventListener(
        "timeupdate",
        this.updateProgress
      );
    },
    // 暂停
    onPause() {
      this.isPlaying = false;
      this.$refs.audioPlayer.removeEventListener(
        "timeupdate",
        this.updateProgress
      );
    },
    // 更新进度条
    updateProgress() {
      this.nowtime = this.$refs.audioPlayer.currentTime;
      this.currentProgress = this.formatSecondsToMSS(this.nowtime);

      this.currentPercent = (this.nowtime / this.alltime) * 100;
    },
    // 拖动进度条 调整播放时间
    // seekTo(event) {
    //   const seekTime = event.target.value;
    //   this.$refs.audioPlayer.currentTime = seekTime;
    // },
  },
  mounted() {
    // 设置初始音频源
    this.$refs.audioPlayer.src = this.currentSong.src;
    this.$refs.audioPlayer.load(); // 确保加载音频源
    // 获取歌曲总时长
    this.$refs.audioPlayer.addEventListener("loadedmetadata", () => {
      this.alltime = this.$refs.audioPlayer.duration;
      this.currentSongDuration = this.formatSecondsToMSS(this.alltime);
    });
  },
};
</script>

<style scoped>
/* Spotify music card made by: csozi | Website: www.csozi.hu*/

.card {
  width: 250px;
  height: 130px;
  background: linear-gradient(45deg, #002fa7, #22c358);
  border-radius: 10px;
  padding: 10px;
  transition: background 3s;
}

.card:hover {
  background: linear-gradient(75deg, #002fa7, #22c358);
  box-shadow: 14px 14px 42px #828386, -14px -14px 42px #ffffff;
}

.top {
  position: relative;
  width: 100%;
  gap: 10px;
}

.pfp {
  position: relative;
  top: 5px;
  left: 5px;
  height: 70px;
  width: 70px;
  /* background-color: #d2d2d2; */
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.title-1 {
  color: white;
  font-size: 25px;
  font-weight: bolder;
  margin-top: 5px;
}

.title-2 {
  color: white;
  font-size: 12px;
  font-weight: bold;
}

.time {
  width: 90%;
  background-color: white;
  height: 6px;
  border-radius: 3px;
  position: absolute;
  left: 5%;
  bottom: 22px;
}

.elapsed {
  /* width: 42%; */
  background-color: #1db954;
  height: 100%;
  border-radius: 3px;
}

.controls {
  color: white;
  display: flex;
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
  justify-content: center;
}

.volume {
  height: 100%;
  width: 48px;
}

.air {
  height: 100%;
  width: 48px;
}

.controls svg {
  cursor: pointer;
  transition: 0.1s;
}

.controls svg:hover {
  color: #1db954;
}

.volume {
  opacity: 1;
  position: relative;
  transition: 0.2s;
}
.myvolume {
  width: 90%;
  height: 6px;
  -webkit-appearance: none; /*移除默认样式*/
  background-color: white; /* 设置轨道背景颜色 */
  border-radius: 3px; /* 设置轨道角半径 */
}

.myvolume::-webkit-slider-thumb {
  -webkit-appearance: none; /*移除默认样式*/
  width: 9px; /* 设置滑块宽度 */
  height: 9px; /* 设置滑块高度 */
  background-color: #1db954; /* 设置滑块背景颜色 */
  border-radius: 50%; /* 设置滑块为圆形 */
  cursor: pointer; /* 设置鼠标悬停时的光标样式 */
}

.timetext {
  color: white;
  font-size: 8px;
  position: absolute;
}

.time_now {
  bottom: 11px;
  left: 10px;
}

.time_full {
  bottom: 11px;
  right: 10px;
}

.playing {
  display: flex;
  position: relative;
  justify-content: center;
  gap: 1px;
  width: 30px;
  height: 20px;
}

.greenline {
  background-color: rgb(252, 252, 252);
  height: 30px;
  width: 4px;
  position: relative;
  transform-origin: bottom;
}

.line-1 {
  animation: infinite playing 1s ease-in-out;
  animation-delay: 0.2s;
}

.line-2 {
  animation: infinite playing 1s ease-in-out;
  animation-delay: 0.5s;
}

.line-3 {
  animation: infinite playing 1s ease-in-out;
  animation-delay: 0.6s;
}

.line-4 {
  animation: infinite playing 1s ease-in-out;
  animation-delay: 0s;
}

.line-5 {
  animation: infinite playing 1s ease-in-out;
  animation-delay: 0.4s;
}

.line-6 {
  animation: infinite playing 1s ease-in-out;
  animation-delay: 0.7s;
}

@keyframes playing {
  0% {
    transform: scaleY(0.1);
  }

  33% {
    transform: scaleY(0.6);
  }

  66% {
    transform: scaleY(0.9);
  }

  100% {
    transform: scaleY(0.1);
  }
}
</style>