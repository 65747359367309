<template>
    <div>
        <h1>Qwen-Audio-Chat</h1>
    </div>
</template>

<script>
export default{
    name:"Qwen_Audio_Chat"
}
</script>

<style>

</style>