<template>
    <div>
            <img src="../imgs/DeepSeek.svg" alt="">
            <br>
            <br>
            <el-descriptions title="DeepSeek信息" :column="1">

                <el-descriptions-item label="model">deepseek-coder</el-descriptions-item>
                <el-descriptions-item label="max_tokens">2048</el-descriptions-item>
       
                <el-descriptions-item label="temperature">1</el-descriptions-item>
                <el-descriptions-item label="frequency_penalty">0</el-descriptions-item>
                <el-descriptions-item label="presence_penalty">0</el-descriptions-item>
                <el-descriptions-item label="stop"><el-tag size="small"  type="danger">null</el-tag></el-descriptions-item>
                <el-descriptions-item label="stream"><el-tag size="small"  type="danger">false</el-tag></el-descriptions-item>
                <el-descriptions-item label="top_p">1</el-descriptions-item>
                <el-descriptions-item label="logprobs"><el-tag size="small"  type="danger">false</el-tag></el-descriptions-item>
                <el-descriptions-item label="top_logprobs"><el-tag size="small"  type="danger">null</el-tag></el-descriptions-item>
           
                <el-descriptions-item label="备注">
                    <el-tag size="small">不可更改</el-tag>
                    <el-tag size="small"  type="success"  style="margin-left: 10px;">小型</el-tag>
                </el-descriptions-item>
                
            </el-descriptions>

  

    </div>
</template>

<script>
export default {
    name: "informationCard"
}

</script>

<style scoped></style>