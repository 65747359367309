<template>
    <div>
        <div class="container">
            <div class="container_terminal"></div>
            <div class="terminal_toolbar">
                <div class="butt">
                    <button class="btn btn-color"></button>
                    <button class="btn"></button>
                    <button class="btn"></button>
                </div>
                <p class="user">Dawn's Linux: ~</p>
            </div>
            <div class="terminal_body">
                <div class="terminal_promt">
                    <span class="terminal_user">Dawn@admin:</span>
                    <span class="terminal_location">~</span>
                    <span class="terminal_bling">$</span>
                    <span class="terminal_bling">{{ linuxCode }}<span class="terminal_cursor"></span></span>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'mylinux',
    props: ['linuxCode']


}


</script>

<style>
.container {
    width: 100%;
    max-height: 300px;
    border-radius: 5px;
}

.terminal_toolbar {
    display: flex;
    height: 30px;
    align-items: center;
    padding: 0 8px;
    box-sizing: border-box;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: linear-gradient(#504b45 0%, #3c3b37 100%);
}

.butt {
    display: flex;
    align-items: center;
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-right: 5px;
    font-size: 8px;
    height: 12px;
    width: 12px;
    box-sizing: border-box;
    border: none;
    border-radius: 100%;
    background: linear-gradient(#7d7871 0%, #595953 100%);
    text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 1px 0px #41403A, 0px 1px 1px 0px #474642;
}

.btn-color {
    background: #ee411a;
}

.btn:hover {
    cursor: pointer;
}

.btn:focus {
    outline: none;
}

.butt--exit {
    background: linear-gradient(#f37458 0%, #de4c12 100%);
}

.user {
    color: #d5d0ce;
    margin-left: 6px;
    font-size: 14px;
    line-height: 15px;
}

.terminal_body {
    background: rgba(56, 4, 40, 0.9);
    min-height: 40px;
    padding-top: 2px;
    margin-top: -1px;
    font-size: 18px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.terminal_promt {
    display: flex;
}

.terminal_promt span {
    margin-left: 4px;
}

.terminal_user {
    color: #7eda28;
}

.terminal_location {
    color: #4878c0;
}

.terminal_bling {
    color: #dddddd;
}

.terminal_cursor {
    display: inline-block;
    height: 18px;
    width: 5px;
    margin-left: 10px;
    animation: curbl 1200ms linear infinite;
    color: #f8f5f5;
}

@keyframes curbl {

    0% {
        background: #ffffff;
    }

    49% {
        background: #ffffff;
    }

    60% {
        background: transparent;
    }

    99% {
        background: transparent;
    }

    100% {
        background: #ffffff;
    }
}
</style>