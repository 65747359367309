<template>
  <div style="margin: 50px">
    <el-row type="flex" justify="space-around" style="height: 60vh">
      <el-col :span="6">
        <!-- <myAiLottie></myAiLottie> -->
        <car></car>
      </el-col>
      <el-col :span="12">
        <div class="myWelcome" style="text-align: center">
          <h1 style="font-size: 8vh">
            This module is designed to allow everyone to experience the joy of
            algorithms
          </h1>

          <h2>--------------</h2>
          <h3>欢迎使用AI在线图像处理算法</h3>
          <h3 style="display: inline-block">我提供了一些在线处理模块</h3>
        </div>
      </el-col>
    </el-row>
    <br />
    <!-- SDXL介绍 -->
    <el-row :gutter="20" type="flex" align="middle">
      <el-col :span="12">
        <el-card shadow="hover">
          <h1 style="font-size: 3vw; margin: 10px 0px; color: #002fa7">DeepSeek大模型</h1>
          <p class="introduce">
            DeepSeek大模型应该是目前最厉害的模型(GTP4另说),在各种榜单上拔得头筹，并且在代码上表现的很优秀。
          </p>
          <p class="introduceEng">
            The DeepSeek large model should be the most powerful large model currently available (with the exception of
            GPT-4), especially in the area of code processing.
          </p>
          <p class="introduce">
            DeepSeek大模型在代码评测中拔的头筹，并且API接入方式比较简单，而且费用很便宜，1万tokens2元钱。因此我决定将其接入网站。
          </p>
          <p class="introduceEng">
            DeepSeek's large model has taken the top spot in code evaluation, and the API access method is relatively
            simple, plus the cost is very cheap, with 10,000 tokens costing only 2 ￥. Therefore, I have decided to
            integrate it into the website.
          </p>
          <p class="introduce" style="display: flex;align-items: center;">
            <span>你可以点击下方按钮进行体验👇</span>
          </p>
          <el-button style="background-color: #22c358;color: white;" @click="toSdxl()">开始</el-button>

        </el-card>
      </el-col>
      <el-col :span="12">

        <img src="./imgs/DeepSeek.svg" alt="" />
      </el-col>
    </el-row>
    <!-- 分割线 -->
    <el-divider><i class="el-icon-mobile-phone"></i></el-divider>
    <!-- IFAN介绍 -->
    <el-row :gutter="20" type="flex" align="middle">
      <el-col :span="12">
        <img src="./imgs/IFAN.png" alt="" />
      </el-col>
      <el-col :span="12">
        <el-card shadow="hover">
          <h1 style="font-size: 3vw; margin: 10px 0px; color: #002fa7">IFAN(未开通端口)</h1>
          <p class="introduce">
            图像去模糊是我的研究方向之一，导致模糊的原因有很多，比如失焦、运动、低分辨率等等，IFAN则是针对失焦模糊的一个算法模型。
          </p>
          <p class="introduceEng">
            Image deblurring is one of my research areas. There are various reasons for blurriness, such as defocus,
            motion, low resolution, and more. IFAN, in particular, is an application designed for addressing defocus
            blur.
          </p>
          <p class="introduce">
            你可以将一张模糊的图像作为输入，算法则会输出一张全焦点清晰的图像，如右图所示。IFAN使用迭代滤波的方法恢复图像的全焦点，该算法在视觉表现上良好。
          </p>
          <p class="introduceEng">
            You can input a blurry image, and the algorithm will produce a fully focused and clear image, as shown in
            the
            right figure. IFAN employs an iterative filtering approach to restore the image to full focus, and the
            algorithm performs well in terms of visual quality.
          </p>
          <p class="introduce" style="display: flex;align-items: center;">
            <span>你可以点击下方按钮进行体验</span><svg t="1700117773320" class="icon" viewBox="0 0 1024 1024" version="1.1"
              xmlns="http://www.w3.org/2000/svg" p-id="1737" width="32" height="32">
              <path
                d="M778.9056 59.9552h-366.592c-41.9328 0-81.152 22.528-102.2976 58.5728l-169.0112 288.256a123.8528 123.8528 0 0 0 25.7536 156.672l3.7376 3.584c44.032 36.3008 112.0256 34.9696 154.624-1.3312V885.76a119.296 119.296 0 0 0 203.776 84.0704 118.784 118.784 0 0 0 34.56-85.248l-1.8944-194.048 246.784-17.1008a100.9664 100.9664 0 0 0 91.8528-100.7616l-2.4576-394.1888a119.3472 119.3472 0 0 0-118.8352-118.528zM215.296 516.4032l-3.072-2.816a56.6272 56.6272 0 0 1-12.9536-72.704l168.96-288.2048a50.5344 50.5344 0 0 1 44.032-25.1904h366.6432c28.16 0 51.1488 23.04 51.3024 51.4048l2.5088 393.9328a33.4336 33.4336 0 0 1-30.1056 33.3824l-245.8624 16.9984a67.4816 67.4816 0 0 0-62.6688 67.9424l1.8432 194.048c0.1536 14.08-5.12 27.1872-14.9504 37.0688a51.8144 51.8144 0 0 1-88.3712-36.5056V357.2736L289.536 505.6a53.76 53.76 0 0 1-74.24 10.8032z"
                fill="#2c2c2c" p-id="1738"></path>
            </svg>
          </p>
          <el-button style="background-color: #22c358;color: white;" @click="toIfan()">开始</el-button>

        </el-card>
      </el-col>

    </el-row>
    <!-- 分割线 -->
    <el-divider>💻</el-divider>

    <el-card>
      <!-- 大模型 -->
      <h1 style="font-size: 3vw; margin: 10px 0px; color: #002fa7">📚大模型知识整理📚</h1>
      <br>
      <el-row>
        <el-col :span="5">
          <el-radio-group v-model="isCollapse" style="margin-bottom: 20px;">
            <el-radio-button :label="false">展开</el-radio-button>
            <el-radio-button :label="true">收起</el-radio-button>
          </el-radio-group>
          <el-menu class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="isCollapse">
            <el-submenu index="1">
              <template slot="title">
                <i class="el-icon-location"></i>
                <span slot="title" style="font-weight: bold;">大模型</span>
              </template>
              <el-menu-item-group>
                <span slot="title">视觉模型</span>
                <el-menu-item index="1-1" @click="toModel(1)">Qwen-vid-Chat</el-menu-item>
                <el-menu-item index="1-2" @click="toModel(2)">Stable-diffusion</el-menu-item>
                <el-menu-item index="1-3" @click="toModel(3)">instruct-pix2pix</el-menu-item>
                <el-menu-item index="1-4" @click="toModel(4)">StableSR</el-menu-item>
              </el-menu-item-group>
              <el-menu-item-group title="NLP模型">
                <el-menu-item index="1-5" @click="toModel(5)">Qwen-72B-Chat</el-menu-item>
                <el-menu-item index="1-6" @click="toModel(6)">Llama-2-7b</el-menu-item>
                <el-menu-item index="1-7" @click="toModel(7)">DISC-FinLLM</el-menu-item>
              </el-menu-item-group>
              <el-menu-item-group title="语音模型">
                <el-menu-item index="1-8" @click="toModel(8)">Qwen-Audio-Chat</el-menu-item>
              </el-menu-item-group>

            </el-submenu>
            <el-menu-item index="2" @click="toModel(9)">
              <i class="el-icon-menu"></i>
              <span slot="title">Huggingface模型下载方法</span>
            </el-menu-item>
            <el-menu-item index="3" @click="toModel(10)">
              <i class="el-icon-document"></i>
              <span slot="title">大模型微调技术</span>
            </el-menu-item>
            <el-menu-item index="4" @click="toModel(11)">
              <i class="el-icon-setting"></i>
              <span slot="title">关于大模型的一些个人见解</span>
            </el-menu-item>
            <el-menu-item index="5" @click="toModel(11)">
              <fangkuai />
            </el-menu-item>

          </el-menu>
        </el-col>
        <!-- 右边展示区域 -->
        <el-col :span="18" style="height: 90vh;overflow: auto;">
          <router-view></router-view>
        </el-col>
      </el-row>



    </el-card>

  </div>
</template>
<script>
// import myAiLottie from "./myAiLottie";
import car from "./myCarLottie"
import fangkuai from "./myLottie"
export default {
  name: "AI",
  mounted() {
    this.toModel(9)
  },
  data() {
    return {
      output: "",
      isCollapse: false
    };
  },
  components: {
    // myAiLottie,
    fangkuai,
    car
  },
  methods: {
    toSdxl() {
      this.$router.push("/DeepSeek");
    },
    toIfan() {
      this.$router.push("/Ifan");
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    toModel(number) {
      if (number == 1) {
        this.$router.push("/Qwen_Vid_Chat");
      }
      if (number == 2) {
        this.$router.push("/Stable_Diffusion");
      }
      if (number == 3) {
        this.$router.push("/Instruct_pix2pix");
      }
      if (number == 4) {
        this.$router.push("/StableSR");
      }
      if (number == 5) {
        this.$router.push("/Qwen_72B_Chat");
      }
      if (number == 6) {
        this.$router.push("/Llama_2_7B");
      }
      if (number == 7) {
        this.$router.push("/DISC_FinLLM");
      }
      if (number == 8) {
        this.$router.push("/Qwen_Audio_Chat");
      }
      if (number == 9) {
        this.$router.push("/Huggingface");
      }
      if (number == 10) {
        window.open('https://www.1ys53p.top/1716106050851.html', '_blank');
      }
      if (number == 11) {
        this.$router.push("/MyBigModel");
      }
    }
  },
};
</script>

<style scoped>
.myWelcome {
  background: linear-gradient(30deg, #22c358, #002fa7);
  -webkit-background-clip: text;
  /* 适用于WebKit内核的浏览器（Chrome、Safari等） */
  color: transparent;
  /* 让文字透明 */
}

p {
  /* color: #626362; */
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bolder;
  font-family: "阿里妈妈灵动体 VF Thin";
}

.cardstyle:hover {
  background: linear-gradient(30deg, #22c358, #002fa7);
  -webkit-background-clip: text;
  /* 适用于WebKit内核的浏览器（Chrome、Safari等） */
  color: transparent;
}

img {
  width: 100%;
}

.introduce {
  font-family: "阿里妈妈灵动体 VF Thin";
  font-weight: bold;
  color: #202020;
  margin-top: 20px;
}

.introduceEng {
  font-family: "阿里妈妈灵动体 VF Thin";
  font-size: small;
  color: #6e6e6e;
  margin-top: 15px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 15vw;
  min-height: 400px;
}
</style>