<template>
    <div>
        <h1>DISC_FinLLM</h1>
    </div>
</template>

<script>
export default{
    name:"DISC_FinLLM"
}
</script>

<style>

</style>