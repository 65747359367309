<template>
    
    <div>
        
        <!-- <h1>2023看完的纪录片</h1> -->

        <video src="./mp4/blue.mp4" loop autoplay class="myvideo"></video>
        <div style="color: white">
            <el-row type="flex" justify="center">
                <el-col :span="14" style="text-align: left; margin-top: 70px">
                    <br />
                    <br />
                    <h1>嗨👋 欢迎查看Dawn的2023年度总结报告！🎉📁🎉</h1>
                    <br />
                    <h3>
                        2023年对Dawn来说很难忘，在这一年，Dawn结识了很多新朋友，有云村歌单重复率高达98%的十四🎧️，有看起来凶巴巴其实很善良的慧仔💛，还有深圳的阿芷,以及公司顶级温柔的<br />
                        豪哥🙋‍♂️＼艾姐💁＼果果姐🙋‍♀️。
                    </h3>
                    <br />
                    <h3>
                        并且Dawn也开始了漫长的实习之旅，由于一边打工一边白嫖公司的显卡😁，所以Dawn在研究方向上也取得了一定的成果，同时在导师介绍下，还体验了一把老师的心酸👨‍🏫，
                    </h3>
                    <br />
                    <h3>
                        当然，事物都有其两面性，有幸福就必有悲哀，2023最大的遗憾莫过于maud的离开，dawn没能留住maud，就像春风留不住那山头憧憬自由的少年一样，
                    </h3>
                    <br />
                    <h3>好了，废话不多说了，现在开始细看Dawn2023的年度总结吧</h3>
                    -----------------------------------------------------------
                </el-col>
                <el-col :span="6" style="text-align: left"></el-col>
            </el-row>
            <br />

            <!-- 2023看完的书籍 -->
            <el-row type="flex" justify="center">
                <el-col :span="14" style="text-align: left">
                    <h1>2023看完的书籍📚🎨📚</h1>
                </el-col>
                <el-col :span="6" style="text-align: left"></el-col>
            </el-row>
            <br />
            <el-row type="flex" justify="space-around">
                <el-col :span="4" style="text-align: center">
                    <el-card>
                        <img src="./images/book1.png" alt="" />
                        <h3>《文豪野犬 Dead Apple》🍎</h3>
                    </el-card>
                </el-col>
                <el-col :span="4" style="text-align: center">
                    <el-card>
                        <img src="./images/book2.png" alt="" />
                        <h3>《侦探AI》🕵</h3>
                    </el-card>
                </el-col>
                <el-col :span="4" style="text-align: center">
                    <el-card>
                        <img src="./images/book3.png" alt="" />
                        <h3>《且听风吟》🎐</h3>
                    </el-card>
                </el-col>
            </el-row>
            <br />
            <el-row type="flex" justify="space-around">
                <el-col :span="4" style="text-align: center">
                    <el-card>
                        <img src="./images/book4.png" alt="" />
                        <h3>《1973年的弹子球》🔮</h3>
                    </el-card>
                </el-col>
                <el-col :span="4" style="text-align: center">
                    <el-card>
                        <img src="./images/book5.png" alt="" />
                        <h3>《挪威的森林》🌲</h3>
                    </el-card>
                </el-col>
            </el-row>
            <br />
            <el-row type="flex" justify="center">
                <el-col :span="14" style="text-align: left">
                    <h3>
                        Dawn在2023年只看了五本书📚《侦探AI》和《文豪野犬》都属于轻小说系列📘，剧情上没有什么较大波动，并且文豪野犬还是动漫🧑‍🎨的同人小说，由于Dawn没有看过动漫💢，所以读起来有点云里雾里的😵‍💫。
                    </h3>
                </el-col>
                <el-col :span="6" style="text-align: left"></el-col>
            </el-row>
            <br />
            <el-row type="flex" justify="center">
                <el-col :span="14" style="text-align: left">
                    <h3>
                        至于村上春树的作品，就如同一杯烈酒🍷，一口下去便沁人心脾。在这个世界🗺️里，村老板是一位永远抽不完烟🚬的躁动少年👦，永远喝不完威士忌🥃的中年白领👨‍💼。他的故事情节或许波动不大，但每一本书都有独特的想象力🤔，让人陶醉其中。
                    </h3>
                </el-col>
                <el-col :span="6" style="text-align: left"></el-col>
            </el-row>
            <br />
            <el-row type="flex" justify="center">
                <el-col :span="14" style="text-align: left">
                    <h3>
                        《且听风呤》中，啤酒女郎👱‍♀的身影浮现在眼前。她如同一杯冰凉的啤酒🍾，散发着诱人的香气🌷，让人心醉神迷。她的微笑，仿佛拥有无限的魔力，能让人忘记一切忧伤与烦恼🙎🏻‍♀。
                    </h3>
                </el-col>
                <el-col :span="6" style="text-align: left"></el-col>
            </el-row>
            <br />
            <el-row type="flex" justify="center">
                <el-col :span="14" style="text-align: left">
                    <h3>
                        而《1973》中的双胞胎女孩👯‍♀️，则是一道迷幻的光芒。她们宛如两颗明亮的星星🌟，默契的眼神中藏着无尽的秘密㊙️。她们的存在，给予了故事更多的可能性和张力，让读者📖沉浸在奇幻的世界中
                    </h3>
                </el-col>
                <el-col :span="6" style="text-align: left"></el-col>
            </el-row>
            <br />
            <el-row type="flex" justify="center">
                <el-col :span="14" style="text-align: left">
                    <h3>
                        而《挪》中的人间白月光绿子，则是一朵绽放的花朵💐。她的美丽如同月光🌘洒在大地上，清冷而又动人。她的存在，让整个故事充满着浪漫的气息💋，让人忍不住想要靠近，去感受她的温暖🟥。
                    </h3>
                </el-col>
                <el-col :span="6" style="text-align: left"></el-col>
            </el-row>
            <br />
            <el-row type="flex" justify="center">
                <el-col :span="14" style="text-align: left">
                    <h3>如果让Dawn推荐一本书，Dawn一定会推荐👉《挪》👈</h3>
                    -----------------------------------------------------------
                </el-col>
                <el-col :span="6" style="text-align: left"></el-col>
            </el-row>
            <br />

            <!-- 2023看完的纪录片 -->
            <el-row type="flex" justify="center">
                <el-col :span="14" style="text-align: left">
                    <h1>2023看完的纪录片⏺︎🌳⏺︎</h1>
                </el-col>
                <el-col :span="6" style="text-align: left"></el-col>
            </el-row>
            <br />

            <el-row type="flex" justify="center">
                <el-col :span="14" style="text-align: left">
                    <h3>*《伊隆-马斯克》就是spaceX那位总裁，属于他的传记纪录片</h3>
                    <br>
                    <h3>*《Free Solo》讲述Alex Honnold徒手攀登酋长岩的故事，很是精彩！</h3>
                    <br>
                    <h3>*《All the Beauty and the Bloodshed》中文译名是“美与血”，讲述戈尔丁一段具有开创性意义的斗争经历</h3>
                    <br>
                    <h3>*《react.js》学前端的都知道react，该片记录了react的诞生和发展历史</h3>
                    <br>
                    <h3>*《The magical world of moss》就是苔藓植物的科普，因为流浪地球掀起了我对moss的兴趣</h3>
                    <br>
                    <h3>*《Cunk on Earth》适合下饭看，很是无厘头，适合练习英语</h3>
                    <br>
                    <h3>*《super natural》今年看过的最棒的纪录片！采用最新的科技捕捉的神奇瞬间！</h3>
                    <br>
                    <h3>*《south pacific》我心中的神片，对没有看过纪录片的朋友，我永远推荐这一部南太平洋</h3>
                    <br>
                    <h3>*《书简阅中国》为数不多的中文纪录片，通过古人的书信来了解历史</h3>
                    <br>
                </el-col>
                <el-col :span="6" style="text-align: left"></el-col>
            </el-row>
            <br />

            <!-- 2023看完的电影 -->
            <el-row type="flex" justify="center">
                <el-col :span="14" style="text-align: left">
                    <h1>2023看完的电影🎬🎥🎬</h1>
                </el-col>
                <el-col :span="6" style="text-align: left"></el-col>
            </el-row>
            <br />
            <el-row type="flex" justify="center">
                <el-col :span="14" style="text-align: left">
                    <h3>1《流浪地球2》差点看哭</h3>
                    <br>
                    <h3>2《满江红》还好先看的流浪地球2</h3>
                    <br>
                    <h3>3《ちひろさん》中文译名是“千寻小姐”，讲述前风俗店女郎的生活，村花的衣着真的好美呀👍</h3>
                    <br>
                    <h3>4《태극기 휘날리며》中文译名是“太极旗飘扬”，讲述了一对被迫被推上战场的亲兄弟的悲情命运</h3>
                    <br>
                    <h3>5《PAMASAME》中文译名是“交通费”，三级片，没啥好说的</h3>
                    <br>
                    <h3>6《Unsere Mütter, unsere Väter》中文译名是“我们的父辈”，物是人非的战争</h3>
                    <br>
                    <h3>7《ONE PIECE FILM RED》中文译名是“海贼王：红发歌姬”，不好看</h3>
                    <br>
                    <h3>8《深海》画面真的好美！可惜剧情差了一点</h3>
                    <br>
                    <h3>9《BABYLON》中文译名是“巴比伦”，感觉老美总是喜欢开impart</h3>
                    <br>
                    <h3>11《공동경비구역 JSA》中文译名是“共同警备区”，不愧是可以在朝鲜上映的韩国电影</h3>
                    <br>
                    <h3>10《Akira》中文译名是“阿基拉”，喜欢赛博朋克的朋友一定看过</h3>
                    <br>
                    <h3>12《Das Leben der Anderen》中文译名是“窃听风暴”，剧情真的很好</h3>
                    <br>
                    <h3>13《Avatar: The Way of Water》中文译名是“阿凡达2”，画质就是牛逼</h3>
                    <br>
                    <h3>14《Incredibles 2 》中文译名是“超人总动员2”，合家欢电影</h3>
                    <br>
                    <h3>15《Top Gun: Maverick》中文译名是“壮志凌云2：独行侠”，阿汤哥又开飞机了</h3>
                    <br>
                    <h3>16《The Gray Man》中文译名是“灰影人”，动作戏好看，剧情拉胯</h3>
                    <br>
                    <h3>17《길복순》中文译名是“杀死福顺”，爽就完事，别在意剧情</h3>
                    <br>
                    <h3>18《Dungeons & Dragons: Honor Among Thieves》中文译名是“龙与地下城：侠盗荣耀”，虽然剧情俗套，但是还是挺搞笑</h3>
                    <br>
                    <h3>19《John Wick: Chapter 4》中文译名是“疾速追杀4”，叶问大战黑客帝国</h3>
                    <br>
                    <h3>20《Spider-Man: Across The Spider-Verse》中文译名是“蜘蛛侠：纵横宇宙”，当时正和maud决裂，电影全程没心情看</h3>
                    <br>
                    <h3>21《Entebbe》中文译名是“7天”，是个以吹，别看</h3>
                    <br>
                    <h3>22《범죄도시3》中文译名是“犯罪都市3”，现实版一拳超人</h3>
                    <br>
                    <h3>23《新封神榜》我觉得ok，挺好看</h3>
                    <br>
                    <h3>24《The General》中文译名是“将军号”，默片大师巴斯特·基顿</h3>
                    <br>
                    <h3>25《硬汉枪神》网剧堪比院线</h3>
                    <br>
                    <h3>26《第八嫌疑人》真让人失望</h3>
                    <br>
                    <h3>27《燃冬》文艺风格太重啦</h3>
                    <br>
                    <h3>28《밀수》中文译名是“走私”，赵寅成打架真厉害！</h3>
                    <br>
                    <h3>29《すずめの戸締まり》中文译名是“铃芽户缔”，新海诚的旅游宣传片</h3>
                    <br>
                    <h3>30《Inglourious Basterds》中文译名是“无耻混蛋”，昆丁的意淫</h3>
                    <br>
                    <h3>31《아수라》中文译名是“阿修罗”，全片没一个好人</h3>
                    <br>
                    <h3>32《夢》黑泽明大师的作品，预言篇</h3>
                    <br>
                    <h3>33《베를린》中文译名是“柏林”，全智贤就是漂亮！</h3>
                    <br>
                    <h3>34《衛斯理傳奇》王祖贤更美！</h3>
                    <br>
                </el-col>
                <el-col :span="6" style="text-align: left"></el-col>
            </el-row>
            <!-- 2023完成的目标 -->
            <el-row type="flex" justify="center">
                <el-col :span="14" style="text-align: left">
                    <h1>2023的目标完成情况🏷️💯👍</h1>
                </el-col>
                <el-col :span="6" style="text-align: left"></el-col>
            </el-row>
            <br>
            <el-row type="flex" justify="center">
                <el-col :span="14" style="text-align: left">
                    <h3>1、完成前端学习任务，建立个人网站✅</h3>
                    <h3>2、将超分辨率算法实验推进，写出一篇小论文❌️</h3>
                    <h3>3、完成实习任务✅</h3>
                    <h3>4、练出几块腹肌❌️</h3>
                    <h3>5、单身一年✅</h3>
                </el-col>
                <el-col :span="6" style="text-align: left"></el-col>
            </el-row>
            <br />
            <el-row type="flex" justify="center">
                <el-col :span="14" style="text-align: left">
                    <h3>算是中规中矩吧，腹肌真的很难练啊！论文写了一半了，Dawn真的很努力在写了！</h3>
                    <br>
                    <h3>单身一年的目标也是顺利完成了，其实就Dawn现在这状态，想脱单太难了</h3>
                    <br>
                    <h3>同时今年Dawn写了大约50多篇日记，大约每周写一篇，记录一下生活和事情，同时梳理一下接下来的任务，由于大部分都涉及隐私，所以在这里就不展示了。</h3>
                    <br>
                    <h3>最后，新的一年，希望大家都可以实现自己的目标和愿望！也希望Dawn的论文可以顺利出刊吧！</h3>
                    <br>
                </el-col>
                <el-col :span="6" style="text-align: left"></el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    name: "my2023",
};
</script>

<style scoped>
.myvideo {
    position: fixed;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* 保持视频比例，覆盖整个屏幕 */
    z-index: -2;
}

img {
    width: 100%;
}
</style>