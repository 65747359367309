<template>
  <div>
    <div ref="vantaRef" class="vantaRef" style="margin-top: 50px; margin-bottom: 20px">
      <!-- background-image: url('..//assets/imgs/home.png');background-position: center; -->
      <el-row type="flex" align="middle">
        <el-col :span="12">
          <typewriter ref="typewriter" :interval="100" :style="{ visibility: status }">
            <div class="myWelcome" style="text-align: center">
              <h1 style="font-size: 9vh">Welcome to Dawn's personal website</h1>
              <br />
              <h1 style="font-size: 8vh">May everyday bring you happiness</h1>

              <br />
              <h3>嗨，你好呀</h3>
              <h3 style="display: inline-block">欢迎来到我的个人网站！</h3>
              <h2>--------------</h2>
            </div>
          </typewriter>
        </el-col>
        <el-col :span="12">
          <mylottie></mylottie>
        </el-col>
      </el-row>
    </div>
    <!-- 网站简介 -->
    <div style="background-color: #f2f2f2; padding: 50px 50px">
      <el-row>
        <el-col :span="12">
          <h1 style="font-size: 3vw; margin: 10px 0px; color: #002fa7">
            简单介绍一下我的网站
          </h1>
          <h1 style="font-size: 2vw">Introduction to the website</h1>
          <p class="myWebIntrooduce">
            我建立这个网站的目的是分享生活。我希望通过这个平台，能够与您分享我的经验、见解以及我所深爱的艺术。
          </p>
          <p class="myWebIntrooduce">
            无论您是新手还是专家，我都希望我的内容能够对您有所启发和帮助
          </p>
          <p class="myWebIntrooduceEnglish">
            The purpose of establishing this website is to share life. I hope to
            share my experience, insights, and beloved art with you through this
            platform. Whether you are a novice or an expert, I hope my content
            can inspire and help you
          </p>
          <p style="margin-top: 20px" class="myWebIntrooduce">
            在这个网站上，您将找到关于AI的文章、教程、作品展示等。我尽力使内容有足够的深度，同时也保持易于理解。我希望这些内容能够为您提供新的视角
          </p>
          <p class="myWebIntrooduceEnglish">
            On this website, you will find articles, tutorials, work displays,
            and more about AI. I try my best to make the content deep enough
            while also keeping it easy to understand. I hope these contents can
            provide you with a new perspective
          </p>
          <p class="myWebIntrooduce" style="margin-top: 20px">
            <a href="" style="text-decoration: none; color: #002fa7">去看看我的文章
              <svg t="1692842691541" class="icon" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="6299" width="25" height="25">
                <path
                  d="M620.8 179.2c12.8 12.8 6.4 32-6.4 44.8-19.2 6.4-38.4 6.4-44.8-12.8-44.8-70.4-128-115.2-217.6-115.2-140.8 0-256 115.2-256 256 0 89.6 44.8 166.4 115.2 217.6 19.2 6.4 19.2 25.6 12.8 38.4-12.8 19.2-32 19.2-44.8 12.8C89.6 563.2 32 460.8 32 352c0-179.2 140.8-320 320-320 108.8 0 211.2 57.6 268.8 147.2zM326.4 332.8l243.2 601.6 83.2-243.2c6.4-19.2 19.2-32 38.4-38.4L934.4 576 326.4 332.8z m25.6-57.6L960 518.4c32 12.8 51.2 51.2 38.4 83.2-6.4 19.2-19.2 32-38.4 38.4l-243.2 83.2L633.6 960c-12.8 32-44.8 51.2-83.2 38.4-19.2-6.4-32-19.2-38.4-38.4L268.8 358.4c-12.8-32 6.4-70.4 38.4-83.2 12.8-6.4 32-6.4 44.8 0z"
                  fill="#002FA7" p-id="6300"></path>
              </svg></a>
          </p>
        </el-col>
        <el-col :span="12" style="padding: 10px 10px; display: flex; justify-content: center">
          <!-- 右边的插画 -->
          <img src="@/assets/imgs/plant.png" alt="" style="width: 60%" />
        </el-col>
      </el-row>
    </div>
    <!-- 内容区域 -->
    <div class="cc" style="margin: 30px 100px; border-right: 1px solid #bcbbbb">
      <el-row type="flex" align="middle">
        <el-col :span="8">
          <div>
            <img src="../../assets/微信图片_20230206145629.jpg" alt="" style="width: 100%" />
          </div>
        </el-col>
        <el-col :span="12">
          <div class="cc1" style="
              background-color: white;
              padding: 30px 0px 30px 40px;
              margin-left: -10%;
            ">
            <h1 style="font-size: 3vw; margin: 10px 0px; color: #002fa7">
              为什么使用这个头像？
            </h1>
            <h1 style="font-size: 2vw">Logo information</h1>
            <p class="myWebIntrooduce">
              灵感来自村上春树的《ノルウェイの森》(挪威的森林),第九章
            </p>
            <p class="myWebIntrooduce">绿子向渡边彻表达自己爱意的时候说：</p>
            <p class="myWebIntrooduce" style="color: #22c358">
              "私はあなたが好きで，春の熊が好きなように"
            </p>
            <p class="myWebIntrooduce">"最最喜欢你了，像喜欢春天的熊一样"</p>
            <p class="myWebIntrooduceEnglish">
              "I am fond of you, much like a bear in spring"
            </p>
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center" style="margin-top: 30px" :gutter="30">
        <el-col :span="7">
          <img src="@/assets/0d4437d96d91d53cfc82a2e397e5f3e.jpg" alt="" style="width: 100%; height: auto" />
          <h5 style="margin: 10px 0px; color: rgb(59, 59, 59)">The Ocean</h5>
          <a href="" style="color: black; text-decoration: none">
            <h3>
              外伶仃岛海岸线
              <svg t="1692949855247" class="icon" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="3799" width="32" height="32">
                <path
                  d="M455.68 404.032l91.264 529.152c0 0 67.456-69.44 123.136-117.888l122.432 163.904c4.928 6.656 15.68 7.104 23.872 1.088l52.288-38.208c8.256-6.016 10.944-16.32 5.952-22.976l-119.104-159.424c62.208-25.088 164.672-53.632 164.672-53.632L455.68 404.032zM308.352 648.384l-135.872 99.328c-20.544 15.04-24.256 43.968-8 65.408 16.256 21.376 46.272 27.008 66.752 12.032l135.872-99.328c20.992-15.36 24.512-45.504 8.256-66.88C359.168 637.504 329.344 633.024 308.352 648.384zM949.696 238.976c-16.256-21.376-45.632-26.176-67.072-10.496l-134.912 98.688c-21.44 15.68-25.152 44.672-8.896 66.048 16.256 21.376 46.272 27.008 67.712 11.328l134.912-98.688C962.88 290.176 965.952 260.352 949.696 238.976zM319.296 136.832c-15.936-20.928-45.248-25.728-66.752-10.048-20.096 14.72-24.256 43.968-8.32 64.896l105.536 138.816c15.936 20.992 45.696 25.408 65.792 10.688 21.44-15.68 25.216-44.608 9.28-65.6L319.296 136.832zM585.792 301.76c26.176 4.224 50.24-13.376 53.632-39.232l21.184-167.808c3.392-25.792-14.976-49.984-41.536-54.656-26.176-4.224-50.24 13.376-53.632 39.168l-21.248 167.872C540.928 272.96 559.296 297.088 585.792 301.76zM329.728 489.024c2.56-25.92-15.808-50.048-41.536-54.656l-170.048-27.968c-27.072-3.584-50.688 13.696-53.632 39.232-3.904 26.944 14.464 51.072 41.536 54.656l170.048 27.968C301.824 532.736 325.504 515.456 329.728 489.024z"
                  fill="#272636" p-id="3800"></path>
              </svg>
            </h3>
          </a>

          <h5>_______</h5>
          <h5 style="font-style: italic; margin-top: 10px">by Dawn</h5>
        </el-col>
        <el-col :span="7">
          <img src="../../assets/陆丰海岸.jpg" alt="" style="width: 100%; height: auto" />
          <h5 style="margin: 10px 0px; color: rgb(59, 59, 59)">The Ocean</h5>
          <h3>汕尾·陆丰 海岸线</h3>
          <h5>_______</h5>
          <h5 style="font-style: italic; margin-top: 10px">
            by Dawn
          </h5>
        </el-col>
        <el-col :span="7">
          <img src="@/assets/初予婚纱.jpg" alt="" style="width: 100%; height: auto" />
          <h5 style="margin: 10px 0px; color: rgb(59, 59, 59)">Wedding Dress</h5>
          <!-- 连接地址 -->
          <a href="" style="color: black; text-decoration: none">
            <h3>
              初予婚纱·冬
              <svg t="1692949855247" class="icon" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="3799" width="32" height="32">
                <path
                  d="M455.68 404.032l91.264 529.152c0 0 67.456-69.44 123.136-117.888l122.432 163.904c4.928 6.656 15.68 7.104 23.872 1.088l52.288-38.208c8.256-6.016 10.944-16.32 5.952-22.976l-119.104-159.424c62.208-25.088 164.672-53.632 164.672-53.632L455.68 404.032zM308.352 648.384l-135.872 99.328c-20.544 15.04-24.256 43.968-8 65.408 16.256 21.376 46.272 27.008 66.752 12.032l135.872-99.328c20.992-15.36 24.512-45.504 8.256-66.88C359.168 637.504 329.344 633.024 308.352 648.384zM949.696 238.976c-16.256-21.376-45.632-26.176-67.072-10.496l-134.912 98.688c-21.44 15.68-25.152 44.672-8.896 66.048 16.256 21.376 46.272 27.008 67.712 11.328l134.912-98.688C962.88 290.176 965.952 260.352 949.696 238.976zM319.296 136.832c-15.936-20.928-45.248-25.728-66.752-10.048-20.096 14.72-24.256 43.968-8.32 64.896l105.536 138.816c15.936 20.992 45.696 25.408 65.792 10.688 21.44-15.68 25.216-44.608 9.28-65.6L319.296 136.832zM585.792 301.76c26.176 4.224 50.24-13.376 53.632-39.232l21.184-167.808c3.392-25.792-14.976-49.984-41.536-54.656-26.176-4.224-50.24 13.376-53.632 39.168l-21.248 167.872C540.928 272.96 559.296 297.088 585.792 301.76zM329.728 489.024c2.56-25.92-15.808-50.048-41.536-54.656l-170.048-27.968c-27.072-3.584-50.688 13.696-53.632 39.232-3.904 26.944 14.464 51.072 41.536 54.656l170.048 27.968C301.824 532.736 325.504 515.456 329.728 489.024z"
                  fill="#272636" p-id="3800"></path>
              </svg>
            </h3>
          </a>

          <h5>_______</h5>
          <h5 style="font-style: italic; margin-top: 10px">
            by ChuYu
          </h5>
        </el-col>
      </el-row>
    </div>
    <!-- 轮播图 -->
    <div style="margin-bottom: 20px">
      <el-row style="margin-top: 30px">
        <el-col :span="24">
          <div style="height: 20px; width: 100%; background-color: #f2f2f2"></div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-carousel :interval="4000" height="70vh">
            <!-- 大海 -->
            <el-carousel-item style="background-color: white">
              <el-row style="height: 70vh">
                <el-col :span="18">
                  <img src="@/assets/imgs/woter.jpg" alt="" style="height: 100%; width: 100%" />
                </el-col>
                <el-col :span="6" style="display: flex; align-items: center; height: 100%">
                  <div style="
                      width: 200%;
                      background-color: white;
                      height: 300px;
                      margin-left: -90%;
                      padding: 20px 20px;
                      display: flex;
                      flex-wrap: wrap;
                      align-items: center;
                      justify-content: center;
                    ">
                    <p class="myPome">
                      今天，是和你的第一次约会，为了这次相聚，我精心打扮了自己
                    </p>
                    <p class="myPome">可真正站在你的面前，我却像个傻孩子</p>
                    <p class="myPome">
                      笨拙地感受着你温柔的波涛，局促地抚摸着你水粼的肌肤，懵懂地亲吻着你的秀发
                    </p>
                    <p class="myPome">
                      来自大山的我用贪婪地眼光看着你，生怕漏掉了哪怕一丝美丽
                    </p>
                    <p class="myPome">
                      这一刻的我，只想躺在你的温柔乡里，安静地听你诉说那蓝色夜晚的回忆
                    </p>
                    <p class="myPome">
                      亲爱的姑娘啊，毕竟这<span style="color: #002fa7">1642</span>公里，我走了整整<span style="color: #002fa7">24</span>年
                    </p>
                    <p class="myPome">--------------写给那位名为海的姑娘</p>
                    <p style="font-family: 'old'; font-size: medium">
                      写于贰零贰贰年捌月贰拾玖日
                    </p>
                  </div>
                </el-col>
              </el-row>
            </el-carousel-item>
            <!-- 小王子 -->
            <el-carousel-item style="background-color: white">
              <el-row style="height: 70vh">
                <el-col :span="18">
                  <img src="@/assets/imgs/外伶仃岛.jpg" alt="" style="
                      height: 100%;
                      width: 100%;
                      background-size: contain;
                      background-position: center;
                    " />
                </el-col>
                <el-col :span="6" style="display: flex; align-items: center; height: 100%">
                  <div style="
                      width: 200%;
                      background-color: white;
                      height: 300px;
                      margin-left: -90%;
                      padding: 20px 20px;
                      display: flex;
                      flex-wrap: wrap;
                      align-items: center;
                      justify-content: center;
                    ">
                    <p class="myPome">
                      这是Dawn第一次看到这么蓝的海，位置是珠海的外伶仃岛
                    </p>
                    <p class="myPome">
                      在海岛上的日子真的很快乐，Dawn喜欢赤脚站在海水里，让海浪一遍又一遍洗刷自己的身体
                    </p>
                    <p class="myPome">
                      喜欢在日落时分，沿着海岸线一路闲逛，那种从心底里升起的自由和放松，Dawn无法用言语形容
                    </p>
                    <p class="myPome">
                      也是在大海的鼓励下，Dawn终于放下的Maud，放下了从前的自己，放下了好多好多的事
                    </p>
                    <p class="myPome">
                      对Dawn来说，放下并不代表遗忘，而是将那份幸福和伤痛的时光小心珍藏，让其成为最香的酒，最好的回忆。
                    </p>
                    <p class="myPome" style="color: #002fa7">2024年1月6日晚</p>
                  </div>
                </el-col>
              </el-row>
            </el-carousel-item>
            <!-- 树叶 -->
            <el-carousel-item style="background-color: white">
              <el-row style="height: 70vh">
                <el-col :span="18">
                  <img src="@/assets/imgs/人间失格.png" alt="" style="height: 100%; width: 80%" />
                </el-col>
                <el-col :span="6" style="display: flex; align-items: center; height: 100%">
                  <div style="
                      width: 200%;
                      background-color: white;
                      height: 300px;
                      margin-left: -90%;
                      padding: 20px 20px;
                      display: flex;
                      flex-wrap: wrap;
                      align-items: center;
                      justify-content: center;
                    ">
                    <p class="myPome">
                      我渐渐地对世间不再小心谨慎了。我慢慢地觉得，所谓的“世间”并没有那么可怕。换句话说，直到现在为止的那种恐怖感有一些“杞人忧天”。
                    </p>
                    <p class="myPome">
                      就好像担心风中数以千计的百日咳细菌，担心澡堂里隐藏着无数令人眼瞎的细菌，担心理发店里潜伏着导致秃头的病菌，担心生鱼片和生烤猪肉牛肉里有绦虫的幼虫、肝蛭，还有虫卵什么的，
                    </p>
                    <p class="myPome">
                      担心不穿鞋走路时有小玻璃碴渣破脚心，玻璃碴进入体内循环，戳破眼珠，令人双目失明。事实上，从“科学”角度看，所谓“成千上万蠕动的细菌”的说法正确，但我同时也开始清楚：只要我彻底否认它们的存在，它们就和我毫无关系，转瞬即逝的“科学幽灵”。
                    </p>
                    <p class="myPome">

                    </p>

                    <p class="myPome" style="color: #22c358">作者：太宰治</p>
                  </div>
                </el-col>
              </el-row>
            </el-carousel-item>
            <!-- 铃芽 -->
            <el-carousel-item style="background-color: white; overflow: hidden">
              <el-row style="height: 70vh">
                <el-col :span="18">
                  <img src="@/assets/imgs/铃芽.jpg" alt="" style="height: 100%; width: 100%" />
                </el-col>
                <el-col :span="6" style="display: flex; align-items: center; height: 100%">
                  <div style="
                      width: 200%;
                      background-color: white;
                      height: 300px;
                      margin-left: -90%;
                      padding: 20px 20px;
                      display: flex;
                      flex-wrap: wrap;
                      align-items: center;
                      justify-content: center;
                    ">
                    <p class="myPome" style="color: #002fa7">
                      すずめの戸締まり（铃芽户缔）这部电影今天看完了，这又是一部新海诚的“灾难片”
                    </p>
                    <p class="myPome">
                      画面还是那么精美漂亮，人物塑造方面也中规中矩，虽然没有初见《君名》那样惊艳，也没有《秒五》那样刻苦铭心，我仍然觉得这是一部好作品
                    </p>
                    <p class="myPome">
                      铃芽究竟在寻找什么呢？虽然看到一半就知道故事的大致走向，可有一点还是不太明白，那只猫咪“大臣”，真的就只是一块用于镇压的“要石”吗？观影过程中我一直猜测猫咪其实是铃芽的妈妈，妈妈是因为镇压地龙而成为“要石”……
                    </p>
                    <p class="myPome">
                      可惜新海诚最终也没有解释“大臣”，也没有花费篇幅去解释铃芽妈妈遭遇的海啸，也算是给观众留下了想象的空间吧。
                    </p>
                    <p class="myPome">写于2023年9月3日晚</p>
                  </div>
                </el-col>
              </el-row>
            </el-carousel-item>



          </el-carousel>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import mylottie from "./mylottie";
import typewriter from "vue-type-writer";
import * as THREE from "three";
import Net from "vanta/src/vanta.net";
export default {
  data() {
    return {
      dialogVisible: false,
      status: "hidden",
    };
  },
  name: "home",

  components: {
    mylottie,
    // catlottie,
    typewriter,
  },
  mounted() {
    this.type();
    this.vantaEffect = Net({
      el: this.$refs.vantaRef,
      THREE: THREE,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      scale: 1.00,
      scaleMobile: 1.00,
      color: 0x656161,
      backgroundColor: 0xf2f2f2,
      points: 15.00,
      spacing: 14.00
    });
    //这里重新设置样式
  },
  beforeDestroy() {
    if (this.vantaEffect) {
      this.vantaEffect.destroy();
    }
  },
  methods: {
    type() {
      this.status = "visible";
      this.$refs.typewriter.$emit("typewrite");
    },
  },
};
</script>

<style scoped>
h1 {
  font-weight: bolder;
  font-family: "阿里妈妈灵动体 VF Thin";
}

.cc {
  background-image: url("../../assets/imgs/cool-background.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.cc1 {
  background-image: url("../../assets/imgs/cool-background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

h4 {
  margin-top: 10px;
}

.myWelcome {
  background: linear-gradient(30deg, #22c358, #002fa7);
  -webkit-background-clip: text;
  /* 适用于WebKit内核的浏览器（Chrome、Safari等） */
  color: transparent;
  /* 让文字透明 */
}

.myWebIntrooduce {
  font-family: "阿里妈妈灵动体 VF Thin";
  font-weight: bold;
  color: #202020;
}

.myWebIntrooduceEnglish {
  font-family: "阿里妈妈灵动体 VF Thin";
  color: #202020;
  font-size: small;
}

p {
  margin: 10px 0px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.myPome {
  font-family: "old";
  font-size: large;
}
</style>