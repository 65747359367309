<template>
  <div style="margin: 80px">
    <el-row :gutter="20">
      <el-col :span="12">
        <h1>
          输入 Input
          <svg style="margin-left: 5px" t="1700135501972" class="icon" viewBox="0 0 1024 1024" version="1.1"
            xmlns="http://www.w3.org/2000/svg" p-id="5855" width="32" height="32">
            <path
              d="M693.282133 102.4l-513.706666 527.394133c-3.908267 4.061867-4.727467 4.864-6.2464 8.4992l-2.3552 6.0928-3.515734 10.410667-13.0048 40.516267-25.821866 82.090666-41.762134 134.0928c-7.953067 25.6512 15.377067 49.664 40.823467 43.229867l43.195733-14.062933 164.420267-54.254934 44.8-15.138133 8.96-3.157333c1.314133-0.494933 2.389333-0.904533 3.345067-1.297067l3.874133-1.604267c3.584-1.553067 4.386133-2.389333 8.260267-6.212266l514.048-527.8208a68.266667 68.266667 0 0 0 0-95.266134L789.845333 103.68a68.266667 68.266667 0 0 0-96.546133-1.262933zM233.642667 672.170667L740.9152 151.313067l128.785067 132.232533-508.3648 521.966933-27.921067 9.557334-94.122667 31.232-67.601066 22.254933 44.305066-141.448533 15.4112-48.213334 2.218667-6.741333z"
              fill="#002FA7" p-id="5856"></path>
            <path
              d="M578.338133 240.008533a34.133333 34.133333 0 0 1 46.08-1.9968l2.184534 1.9968 153.6 153.6a34.133333 34.133333 0 0 1-46.097067 50.2784l-2.167467-1.9968-153.6-153.6a34.133333 34.133333 0 0 1 0-48.2816z"
              fill="#002FA7" p-id="5857"></path>
            <path
              d="M870.4 853.333333v34.133334a34.133333 34.133333 0 0 1-34.133333 34.133333H529.066667a34.133333 34.133333 0 0 1-34.133334-34.133333v-34.133334h375.466667z"
              fill="#002FA7" p-id="5858"></path>
          </svg>
        </h1>
        <div class="colorLine"></div>
        <el-card>
          <!-- 进行预览 -->
          <img :src="form.imgUrl" v-show="showImage" alt="" style="width: 100%;">
          <h2 style="margin-bottom: 10px;">image文件</h2>
          <el-upload action="https://www.1ys53p.top/upload" drag class="upload-demo"
            :on-success="handlePictureCardPreview">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且一次只能上传一张，分辨率最好超过2k</div>
          </el-upload>
          <el-button style="background-color: #22c358;color: white;margin-top: 10px;" @click="send">开始</el-button>

        </el-card>
      </el-col>
      <el-col :span="12">
        <h1>
          输出 Output
          <svg style="margin-left: 5px" t="1700135668200" class="icon" viewBox="0 0 1024 1024" version="1.1"
            xmlns="http://www.w3.org/2000/svg" p-id="7777" width="32" height="32">
            <path
              d="M800 416h128v512H256v-64H160a32 32 0 0 1-32-32V128a32 32 0 0 1 32-32h608a32 32 0 0 1 32 32v68l96 89.216-96 103.264V416z"
              fill="#FFFFFF" p-id="7778"></path>
            <path
              d="M192 832v32H160a32 32 0 0 1-32-32V128a32 32 0 0 1 32-32h608a32 32 0 0 1 32 32v32h-32V128H160v704h32z m64-416h672v512H256V416z m32 32v448h608V448H288z"
              fill="#5D6D7E" p-id="7779"></path>
            <path d="M480.928 705.792l153.792 153.792 22.624-22.624-176.416-176.416-176.416 176.416 22.624 22.624z"
              fill="#30AD98" p-id="7780"></path>
            <path d="M876.224 826.4l22.624-22.656-187.616-187.616-141.856 141.856 22.624 22.656 119.232-119.232z"
              fill="#30AD98" p-id="7781"></path>
            <path d="M576 576m-64 0a64 64 0 1 0 128 0 64 64 0 1 0-128 0Z" fill="#C49F74" p-id="7782"></path>
            <path
              d="M855.968 269.216L785.28 197.312a16 16 0 1 1 22.816-22.432l111.552 113.504-111.872 107.488a16 16 0 0 1-22.176-23.072l74.496-71.584H624a16 16 0 1 1 0-32h231.968z"
              fill="#27A2DF" p-id="7783"></path>
          </svg>
        </h1>
        <div class="colorLine"></div>
        <img src="../imgs/IFAN.png" alt="" v-show="isShow" style="width: 100%" />
        <img :src="data.result" alt="" style="width: 100%" />
        <myWaiting v-show="isWating"></myWaiting>
        <myFalse v-show="isfalse"></myFalse>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import api from "@/api";
import myWaiting from "../myWaiting";
import myFalse from "../myFalse";
import _ from 'lodash'
export default {
  name: "mySdxl",
  data() {
    return {
      form: {
        imgUrl: 'https://dawnai.cloud/img/6fb5314fa7ab822b04926d3d03a01e8.1b334084.jpg', //用于存储我的服务器返回给我的图像路径
      },
      data: {
        result: '',
      },
      showImage:false,
      isShow: true,
      isWating: false,
      isfalse: false,
    };
  },
  methods: {
    // 上传图片成功的回调
    handlePictureCardPreview(res, file) {
      this.form.imgUrl = res.imageUrl
      this.$message.success('图片上传成功')
      this.showImage=true
      console.log('服务器返回的图像数据地址：',res)
      console.log('服务器返回的数据：',file)
    },
    // 使用es5的写法，加入lodash的节流功能，防止用户频繁点击
    send: _.throttle(function () {
      console.log("点击成功");
      this.data.result = "";
      this.$message.success("开始生成图像，请耐心等待");
      this.isShow = false;
      this.isWating = true;
      this.isfalse = false;
      api
        .getIFANData(this.form)
        .then((data) => {
          this.data.result = data.result;
          console.log(data);
          this.isWating = false;
          this.$message.success("图像渲染成功");
        })
        .catch((error) => {
          console.error("Error:", error);
          this.$message.error("检测到生成的图像违背伦理道德,请重新输入");
          this.isfalse = true;
          this.isWating = false;
        });
    }, 5000)

  },
  components: {
    myWaiting,
    myFalse,
  },
};
</script>
  
<style scoped>
h1 {
  display: flex;
  /* align-content: center; */
  align-items: center;
}

.colorLine {
  margin: 10px 0px 20px 0px;
  height: 2px;
  background: linear-gradient(to right, #002fa7, #22c358);
}

.myUpload {
  border: 1px solid red;
}
</style>