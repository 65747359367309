<template>
    <div>
        <div ref="htmlContainer" v-if="loaded"></div>
    </div>
</template>

<script>
import axios from 'axios';
export default{
    name:"Llama_2_7B",
    data() {
    return {
      loaded: false,
      htmlContent: ''
    };
  },
  async mounted() {
    try {
      const response = await axios.get('https://www.1ys53p.top/171606050851.html', { responseType: 'text' });
      this.htmlContent = response.data;
      this.loaded = true;
    } catch (error) {
      console.error('Failed to load HTML content:', error);
    }
  },
  updated() {
    if (this.loaded) {
      this.$refs.htmlContainer.innerHTML = this.htmlContent;
    }
  }


}
</script>

<style>

</style>