import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import router from '@/router' //引入路由配置
import VuePageTransition from 'vue-page-transition' //引入过渡动画

// 引入代码展示效果
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/blackboard.css'; // 可选，选择一个主题
import 'codemirror/mode/python/python.js'; // 导入Python语言模式
import VueCodemirror from 'vue-codemirror';

// 使用后过渡动画
Vue.use(VuePageTransition)
// 使用element排版UI
Vue.use(ElementUI);
// 使用代码展示组件
Vue.use(VueCodemirror);
Vue.config.productionTip = false

new Vue({
   // 将路由配置应用于Vue实例
  render: h => h(App),
  router
}).$mount('#app')
