<template>
  <div id="app">
    <myAudio class="myAudio"/>
    <myheader></myheader>
    <vue-page-transition mode="fade">
      <router-view></router-view>
    </vue-page-transition>
    <myFooter></myFooter>
  </div>
</template>
<script>
import myAudio from '@/components/myAudio'
import myheader from '../src/components/myHeader'
import myFooter from '../src/components/myFooter'
export default {
  name: 'App',
  components: {
    myheader,
    myFooter,
    myAudio
  },
  mounted(){
    this.checkMobile();
  },
  methods:{
    checkMobile() {
      const isMobile = /iPhone|iPod|Android|BlackBerry|Opera Mini|IEMobile|Mobile/i.test(navigator.userAgent);
      if (isMobile) {
        this.$message.error('Dawn正在开发移动端页面，目前还请使用电脑端访问');
        // 如果需要重定向到移动端路由，可以在这里添加跳转逻辑
        // this.$router.replace('/mobile-index');
        window.location.href = 'https://www.1ys53p.top/1719450691389.html';


      } 
    }
  }
}
</script>

<style>
* {
  margin: 0px;
  padding: 0px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.myAudio{
  position: fixed; /* 使用固定定位 */
    top: 30vh; /* 上边距为0，可以根据需要调整 */
    right: 0; /* 右边距为0，使其固定在屏幕右侧 */
    width: 200px; /* 根据需要设置宽度 */
    height:200px; /* 高度为100%，使其占据整个屏幕高度，也可以根据需要调整 */
    background-color: #f0f0f0; /* 添加背景颜色以便于观察 */
    z-index: 999;
}
</style>
