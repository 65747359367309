<template>
    <div>
        <h1>Instruct_pix2pix</h1>
    </div>
</template>

<script>
export default{
    name:"Instruct_pix2pix"
}
</script>

<style>

</style>