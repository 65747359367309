// api.js
import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'https://www.1ys53p.top', // 替换成你的 API 地址 生产环境使用：https://www.1ys53p.top 开发环境使用：http://127.0.0.1:3000
  timeout: 500000 // 设置请求超时时间
});

export default {
  // 发送get留言板数据请求
  getLetterData() {
    return apiClient.get('/letterInformation')
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  },
  // 发送post留言板数据请求
  sendLetterData(data) {
    return apiClient.post('/sendLetterInformation',data)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  },


  // 发送sdxl算法请求
  getSdxltData(data) {
    return apiClient.post('/make-prediction', data)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  },

  // 发送IFAN算法请求
  getIFANData(data) {
    return apiClient.post('/make-IFAN', data)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  }
};