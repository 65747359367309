<template>
  <div>
    <el-skeleton
      :loading="loading"
      animated
      :throttle="3"
    >
    <template slot="template">
      <div >
          <el-skeleton-item variant="h3" style="width: 50%;" />
          <div
            style="display: flex; align-items: center; justify-items: space-between; margin-top: 16px; height: 16px;"
          >
            <el-skeleton-item variant="text" style="margin-right: 16px;" />
            <el-skeleton-item variant="text" style="width: 30%;" />
          </div>
        </div>
      <el-skeleton-item
          variant="image"
          style="width: 100%; height:400px;"
        />
    </template>
    <template>
      <h1>{{paperText.title}}</h1>
      
        <el-divider> 
          {{ paperText.ptitle }}
        </el-divider>
        <p>{{paperText.text}}</p>
        <br>
        <img :src="paperText.imageUrl" alt="" style="width: 100%;">
        <br> 
        <p class="mytime">{{ paperText.time}}</p>
        <p class="mytime">...</p>    
    </template>
  </el-skeleton>

  </div>
</template>

<script>
export default{
    name:'cardVue',
    props: ['paperText'],
    data(){
      return {
        loading:true
      }
    },
    mounted(){
      setTimeout(()=>{
        this.loading = false
      },3000)
    }
}
</script>

<style scoped>
p{
  font-family: "阿里妈妈刀隶体";
  font-size:x-large;
  /* font-weight: bolder; */
}
h1{
    font-family: "old";  
}
.mytime{
  /* font-family: "阿里妈妈灵动体 VF Thin"; */
  font-size:medium;
  text-align: right;
  font-style: oblique;
  color:rgb(111, 109, 109)
}
</style>