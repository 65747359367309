<template>
  <div style="margin: 80px">
    <el-row  :gutter="20">
      <el-col :span="5">
        <informationCard  />
      </el-col>
      <el-col :span="16" class="chat-container">
        <div class="message-container" ref="messageContainer">

          <div v-for="(message, index) in messages" :key="index" class="message">
            <!-- 动态绑定样式，奇数数代表User，放在右边。  偶数代表DeepSeek放在左边 -->
            <div :class="{ 'displayMessageDeepSeek': index % 2 === 0, 'displayMessageUser': index % 2 !== 0 }">
              {{message }}</div>

            <div style="height:5px;"></div>

          </div>
          <myWatting v-show="iswating" style="margin-left: 20px;" />
        </div>
        
        <br>
        <div class="centerInput">
          <input type="text" placeholder="请输入问题   Enter 发送" v-model="newMessage" @keyup.enter="sendMessage" class="input">
          <button class="myUploadButton" @click="sendMessage">发送</button>
        </div>
      </el-col>

    </el-row>
  </div>
</template>

<script>
import api from "@/api";
// import _ from 'lodash';
import myWatting from "./myWatting.vue"
import informationCard from "./informationCard.vue"
export default {
  name: "MySdxl",
  data() {
    return {
      messages: ['🤖:你好，我是 DeepSeek 通用对话助手，Dawn已经成功将我接入网站，现在你可以试着问一些问题。'],  //对话框展示内容
      newMessage: "",   //输入框内容
      form: {
        contend: ''  //提交给后端内容
      },
      iswating: false,

    };
  },
  methods: {
    // 使用 es5 的写法，加入 lodash 的节流功能，防止用户频繁点击
    // 向我的后端发送请求
    sendMessage() {
      // 检查字段是否为空，如果为空不发送指令
      if (this.newMessage.trim() === '') {
        this.$message.warning("消息不能为空");
        return;
      }
      if (this.iswating) {
        this.$message.warning("正在生成内容，请不要重复输入");
        return;
      }
      console.log('发送成功');

      this.iswating = true;  //锁定输入，防止重复提问。
      this.form.contend = this.newMessage // 将用户输入的内容整合成对象发送给后端   
      this.newMessage="👨‍🚀："+this.newMessage //加上头像
      this.messages.push(this.newMessage);   //将用户刚刚输入的东西压栈进入对话框

      

      // 开始向服务器发送东西
      api.getSdxltData(this.form)
        .then((data) => {
          data.result.choices[0].message.content = '🤖:'+data.result.choices[0].message.content
          this.messages.push(data.result.choices[0].message.content);  //将DeepSeek返回的数据压栈进入对话框
          this.iswating = false;  //返回成功后，可以再次输入
        })
        .catch((error) => {
          console.error("Error:", error);
          this.$message.error("服务器过载，请稍候再试");
          this.iswating = false;//返回失败后，也可以再次输入
        });
      this.newMessage = '';

    },
    // 滚动到最底部查看最新消息
    scrollToBottom() {
      const container = this.$refs.messageContainer;
      container.scrollTop = container.scrollHeight;
    }
  },
  // 监听事件，如果messages更新了，那么触发一下滚动，方便查看最新消息。
  watch: {
    messages() {
      if(this.messages.length <=  1){
        print(this.messages.length)
        return ;
      }
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    }
  },
  components: {
    myWatting,
    informationCard
  }
};
</script>

<style scoped>
.chat-container {
  height: 70vh;
  display: flex;
  flex-direction: column;
}

.message-container {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.centerInput {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.input {

  border-radius: 10px;
  outline: 4px solid #002fa7;
  border: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: #f4f4f4;
  outline-offset: 3px;
  padding: 10px 1rem;
  transition: 0.25s;
  width: 85%
}

.myUploadButton {
  margin-left: 20px;
  border-radius: 10px;
  /* outline: 4px solid #22c358; */
  border: 0;
  color: white;
  background-color: #22c358;
  outline-offset: 3px;
  font-size: larger;
  padding: 10px 1rem;
}

.myUploadButton:hover {
  cursor: pointer;
  background-color: #29ed6b;
}

.input:focus {
  outline-offset: 5px;
  background-color: #fff
}

.displayMessageUser {
  display: inline-block;
  float: right;
  border: 3px solid #cd8a17;
  min-height: 30px;
  max-width: 40%;
  border-radius: 10px;
  background: #fffaeb;
  font-size: larger;
  padding: 10px;
  word-wrap: break-word;
  white-space: pre-wrap;
  vertical-align: middle;
}

.displayMessageDeepSeek {
  display: inline-block;
  border: 3px solid #87a89e;
  min-height: 30px;
  max-width: 40%;
  border-radius: 10px;
  background: #fffaeb;
  font-size: larger;
  padding: 10px;
  word-wrap: break-word;
  white-space: pre-wrap;
  vertical-align: middle;
}
</style>