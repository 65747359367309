<template>
    <div>
        <h1>MyBigModel</h1>
    </div>
</template>

<script>
export default{
    name:"MyBigModel"
}
</script>

<style>

</style>