<template>
    <div>
        <h1>QWen</h1>
    </div>
</template>

<script>
export default{
    name:"Qwen-72B-Chat"
}
</script>

<style>

</style>